import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ww-card-actions" }
const _hoisted_3 = ["type", "onUpdate:modelValue", "onChange"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "inline-form-item"
}
const _hoisted_6 = ["type", "placeholder", "onUpdate:modelValue"]
const _hoisted_7 = { class: "ww-card-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_SelectSingleSearch = _resolveComponent("SelectSingleSearch")!

  return (!_ctx.editMode)
    ? (_openBlock(), _createBlock(_component_Card, {
        key: 0,
        title: _ctx.cardTitle,
        class: "card-list-toggle-edit"
      }, {
        default: _withCtx(() => [
          _createElementVNode("table", null, [
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayItems, (item) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: item.uid
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listSettings, (field, index) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: index,
                      class: _normalizeClass({'dense': field.dense })
                    }, _toDisplayString(item[field.property] ? item[field.property] : "/"), 3))
                  }), 128))
                ]))
              }), 128))
            ])
          ]),
          (!_ctx.arrayItems)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.emptyStateText ? _ctx.emptyStateText : _ctx.$t('emptyState.default')), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Button, {
              text: _ctx.$t('button.edit'),
              dense: true,
              onOnClick: _ctx.handleToggleEditMode
            }, null, 8, ["text", "onOnClick"])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : (_openBlock(), _createBlock(_component_Card, {
        key: 1,
        title: _ctx.cardTitle,
        class: "card-list-toggle-edit"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayItems, (item, itemIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: itemIndex,
              class: "inline-form-item list"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listSettings, (field, index) => {
                return _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: index,
                  type: field.type,
                  class: _normalizeClass(["outline", {'fieldGrow': field.fieldGrow }]),
                  "onUpdate:modelValue": ($event: any) => ((item[field.property]) = $event),
                  onChange: ($event: any) => (_ctx.handleChangeInput(item, itemIndex))
                }, null, 42, _hoisted_3)), [
                  [_vModelDynamic, item[field.property]]
                ])
              }), 128)),
              (_ctx.checkIsEdited(item))
                ? (_openBlock(), _createBlock(_component_IconButton, {
                    key: 0,
                    icon: _ctx.store.getters.svgIcons.check,
                    class: _normalizeClass({'lightUp': _ctx.checkIsEdited(item)}),
                    onOnClick: ($event: any) => (_ctx.handleSaveItem(item, itemIndex))
                  }, null, 8, ["icon", "class", "onOnClick"]))
                : (_openBlock(), _createBlock(_component_IconButton, {
                    key: 1,
                    icon: _ctx.store.getters.svgIcons.close,
                    class: _normalizeClass({'lightUp': _ctx.checkIsEdited(item)}),
                    onOnClick: ($event: any) => (_ctx.handleRemoveItem(item))
                  }, null, 8, ["icon", "class", "onOnClick"]))
            ]))
          }), 128)),
          (_ctx.selectSearchProps)
            ? (_openBlock(), _createElementBlock("form", _hoisted_4, [
                _createElementVNode("label", null, _toDisplayString(_ctx.selectSearchProps.label), 1),
                _createVNode(_component_SelectSingleSearch, {
                  placeholder: _ctx.selectSearchProps.placeholder,
                  item: {},
                  options: _ctx.allItems,
                  secondary: true,
                  labelProperty: "title",
                  value: { value: 0, text: '' },
                  allowCreateNew: true,
                  emptyStateText: _ctx.selectSearchProps.emptyListText,
                  onOnChange: _ctx.onSetNewItem,
                  onOnAddItemClicked: _ctx.handleCreateItem
                }, null, 8, ["placeholder", "options", "emptyStateText", "onOnChange", "onOnAddItemClicked"])
              ]))
            : (_openBlock(), _createElementBlock("form", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listSettings, (field, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: index,
                    type: field.type,
                    class: _normalizeClass(["outline", {'fieldGrow': field.fieldGrow }]),
                    placeholder: field.formPlaceHolder,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.form[field.property]) = $event)
                  }, null, 10, _hoisted_6)), [
                    [_vModelDynamic, _ctx.form[field.property]]
                  ])
                }), 128)),
                _createVNode(_component_IconButton, {
                  icon: _ctx.store.getters.svgIcons.add,
                  primary: true,
                  raised: true,
                  onOnClick: _ctx.handleCreateItem
                }, null, 8, ["icon", "onOnClick"])
              ])),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Button, {
              text: _ctx.$t('button.closeEdit'),
              dense: true,
              onOnClick: _ctx.handleToggleEditMode
            }, null, 8, ["text", "onOnClick"])
          ])
        ]),
        _: 1
      }, 8, ["title"]))
}