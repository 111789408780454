
import store from '@/store/index';
import { defineComponent } from 'vue';
import { Button, Card, IconButton, SelectSingleSearch } from '@/ui/index';

export default defineComponent({
    name: "CardListToggleEdit",
    components: {Button, Card, IconButton, SelectSingleSearch },
    props: {
        listSettings: {
            type: Array as any,
            default: null 
        },
        cardTitle: {
            type: String,
            default: ""
        },
        arrayItems: {
            type: Array as any,
            default: null 
        },
        allItems: {
            type: Array as any,
            default: null 
        },
        emptyStateText: {
            type: String,
            default: ""
        },
        selectSearchProps:{
            type: Object,
            default: null
        }
    },
    data() {
        return {
            store,
            editMode: false,
            form: {} as any,
            editedItems: [] as Array<any>
        }
    },
    mounted(){
        //Prepare form
        this.listSettings.forEach((field:any) => {
            this.form[field.property] = (field.type == "text" ? "" : 0)
        });
    },
    methods: {
        checkIsEdited(item:any) {
            if(this.editedItems.includes(item)){
                return true;
            }
            return false
        },
        handleChangeInput(item:any, index:any){
            console.log("handleChangeInput", item, index)
            this.editedItems[index] = item;
            console.log("editedItems", this.editedItems)
            return true
        },
        handleToggleEditMode() {
            this.editMode = !this.editMode;
        },
        handleCreateItem() {
            console.log("form", this.form)
            this.$emit('onCreateItem', this.form);
            
        },
        handleSaveItem(item:any){
            this.$emit('onSaveItem', item);
            this.editedItems = this.editedItems.filter(function(item : any){
                   return item.uid != item.uid;
                });
            },
        handleRemoveItem(item:any) {
            this.$emit('onRemoveItem', item);
        },
        onSetNewItem(item : any, value : number, text: string) {
            console.log("onSetNewItem", item, value, text)
            this.form = {
                uid: value,
                number: "",
                title: text
            }
        }
    }
});
