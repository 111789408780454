import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "timi-dashboard-container" }
const _hoisted_2 = { class: "date-slider" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "statistic-wrapper" }
const _hoisted_7 = { class: "statistic-item" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "statistic-item" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "statistic-item" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "statistic-item" }
const _hoisted_14 = { class: "label" }
const _hoisted_15 = {
  key: 0,
  class: "statistic-item"
}
const _hoisted_16 = { class: "col-6" }
const _hoisted_17 = { class: "list-workbreaks" }
const _hoisted_18 = { class: "workbreak-from" }
const _hoisted_19 = { class: "workbreak-to" }
const _hoisted_20 = { class: "workbreak-to" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Card = _resolveComponent("Card")!
  const _component_TimeEntryCard = _resolveComponent("TimeEntryCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconButton, {
      icon: _ctx.store.getters.svgIcons.arrowbackward,
      class: "go-back-btn",
      label: _ctx.$t('button.back'),
      dense: true,
      onOnClick: _ctx.handleGoBack
    }, null, 8, ["icon", "label", "onOnClick"]),
    _createElementVNode("h2", null, _toDisplayString(_ctx.employee.number) + ": " + _toDisplayString(_ctx.employee.firstName) + " " + _toDisplayString(_ctx.employee.lastName), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IconButton, {
        icon: _ctx.store.getters.svgIcons.arrowbackward,
        class: "",
        onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSwitchDay(-1)))
      }, null, 8, ["icon"]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.displayActiveDay), 1),
      _createVNode(_component_IconButton, {
        icon: _ctx.store.getters.svgIcons.arrowforward,
        onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSwitchDay(1)))
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Card, {
          title: _ctx.$t('day.dailyStatistic')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('day.presenceTime')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.convertSecondsToTime(_ctx.presenceTime)), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('day.workingtimeSUM')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.convertSecondsToTime(_ctx.timeSum)), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('day.comeAt')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.activePresence.come ? _ctx.convertTimeStampToDate(_ctx.activePresence.come) + ', ' + _ctx.convertToTime(_ctx.activePresence.come) + " " + _ctx.$t('label.oClock') : "--"), 1)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('day.goAt')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.activePresence.go ? _ctx.convertTimeStampToDate(_ctx.activePresence.go) + ', ' + _ctx.convertToTime(_ctx.activePresence.go) + " " + _ctx.$t('label.oClock') : "--"), 1)
              ]),
              false
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('day.lastInteractivity')) + ":", 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.latestInteraction + " " + _ctx.$t('label.oClock')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_Card, {
          title: _ctx.$t('day.workbreaks')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workbreaks, (workbreak) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: workbreak.uid,
                  class: "list-entry-workbreak"
                }, [
                  _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('label.workbreakStart')) + " " + _toDisplayString(_ctx.convertTimeStampToTime(workbreak.start)), 1),
                  _createTextVNode(),
                  _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('label.workbreakStop')) + " " + _toDisplayString((workbreak.stop ? _ctx.convertTimeStampToTime(workbreak.stop) : '-')), 1),
                  _createTextVNode(),
                  _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('label.workbreakPaused')) + " " + _toDisplayString((workbreak.getTimePaused() ? _ctx.convertSeconds(workbreak.getTimePaused()) : '-')), 1)
                ]))
              }), 128)),
              (!_ctx.workbreaks.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.workbreakNone')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_Card, {
          title: _ctx.$t('dashboard.activities')
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeEntries, (entry) => {
              return (_openBlock(), _createBlock(_component_TimeEntryCard, {
                key: entry.uid,
                entry: entry
              }, null, 8, ["entry"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["title"])
      ])
    ])
  ]))
}