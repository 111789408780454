
import store from '@/store/index';
import { defineComponent } from "vue";
import { PresenceController, EmployeeController, TimeEntryController, WorkbreakController } from "@/controller/";
import { Employee, Presence, TimeEntry, Workbreak } from "@/model";
import { Card, IconButton } from "@/ui";
import { TimeEntryCard }  from "@/components/index";
import { Converter, DateTimeUtils } from "@/utils";

export default defineComponent({
    name: "DashboardEmployee",
    components: { Card, TimeEntryCard, IconButton },
    props: {
        timeentries: {
            type: Array,
            default: null
        }
    },
    data(){
        return {
            store,
            employeeId: 0,
            employee: {} as Employee,
            timeEntries: [] as Array<TimeEntry>,
            presences: [] as Array<Presence>,
            workbreaks: [] as Array<Workbreak>,
            activeDay: Math.floor(Date.now() / 1000)
        }
    },
    created(){
        this.employeeId = parseInt(this.$route.params.id as string)
        this.getEmployee();
    },
    computed: {
        displayActiveDay() : string {
            return DateTimeUtils.convertTimeStampToDate(this.activeDay, true)
        },        
        timeSum() {
            let sum = 0;
            this.timeEntries.forEach((entry : any) => {
                sum += entry.getTimeGone()
            })
            return sum;
        },
        latestInteraction() {
            let last = 0;
            this.timeEntries.forEach((entry : any) => {
                if (entry.tstamp > last) {
                    last = entry.tstamp;
                }
            })
            return this.convertToTime(last);
        },
        activePresence() {
            if (this.presences.length) {
                return this.presences[this.presences.length-1]
            } else {
                return {
                    come: 0,
                    go: 0
                }
            }
        },
        presenceTime() {
                //take the last one cause the first is always the running, if there are more
            const pr = this.presences.length ? this.presences[this.presences.length-1] : null
            console.log('presenceTime', pr)
            if (pr) {
                if (pr.go) {
                    return pr.go - pr.come
                } else {
                    return Math.floor(new Date().valueOf() / 1000) - pr.come
                }
            } else {
                return '-'
            }
        }
    },
    methods: {
        convertToTime(ts:number) {
            return Converter.convertTimeStampToTime(ts)
        },
        convertTimeStampToTime(ts: number) {
            return Converter.convertTimeStampToTime(ts)
        },
        convertTimeStampToDate(ts: number) {
            return Converter.convertTimeStampToDate(ts)
        },
        convertSecondsToTime(ts:number) {
            return Converter.convertSecondsToTime(ts)
        },
        convertSeconds(ts: any) {
            return DateTimeUtils.convertSecondsToTime(ts)
        },
        getEmployeeData() {
            if (this.employee) {
                this.getPresences();
                this.getTimeEntries();
                this.getWorkbreaks();
            }
        },

            //handler
        handleSwitchDay(direction: any) {
            this.activeDay += 86400*direction
            const midnights = DateTimeUtils.getMidnightToMidnight(new Date())
            if (this.activeDay > midnights.end) {
                this.activeDay -= 86400;
            } else {
                this.getEmployeeData()
            }
        },   
        handleGoBack() {
            this.$router.push('/administration/dashboard')
        },

            //data
        async getEmployee() {
            if (this.employeeId) {
                const res = await EmployeeController.getEmployeeById(this.employeeId)
                if (res.employee) this.employee = res.employee
                this.getEmployeeData()
            }
        },
        async getTimeEntries() {
            const res = await TimeEntryController.fetchDayTimeEntries(this.employeeId, this.activeDay)
            if(!res.error){
                this.timeEntries = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getPresences() {
            const date = new Date()
            date.setTime(this.activeDay*1000)
            const day = DateTimeUtils.getMidnightToMidnight(date)
            const res = await PresenceController.getPresences(day.start, day.end, this.employeeId);
            if(!res.error){
                this.presences = res.items;
            } else {
                this.presences = []
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }       
            console.log('this.presences', this.presences) 
        },
        async getWorkbreaks() {
            const date = new Date()
            date.setTime(this.activeDay*1000)
            const day = DateTimeUtils.getMidnightToMidnight(date)            
            const response = await WorkbreakController.getWorkbreaks(day.start, day.end, this.employeeId)
            if (response.items) {
                this.workbreaks = response.items
            } else {
                this.workbreaks = []
            }
        }

    },
})
